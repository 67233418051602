import React, { useState, useContext } from "react";
import { Formik, Field } from "formik";

import * as yup from "yup";
import cx from "classnames";
import orderStyle from "./checkoutForm.module.sass";
import Button from "../Button";
import CheckoutButtons from "../../components/CheckoutButtons/CheckoutButtons";
import { navigate } from "gatsby";

import sendIcoMutation from "./SendAresMutation";

import {
    CustomInputComponent,
    Checkbox,
    BirthDateInput,
    RadioButtonGroup,
    RadioButton,
    CustomCompanyComponent,
} from "../Eshop/CustomInput";

import { checkoutContext } from "../../context/checkoutContextProvider";
import {
    CM_GDPR_URL,
    CM_TERMS_AND_CONDITIONS_URL,
    EIGHTEEN_YEARS_AGO,
    KAPITOL_GDPR_URL,
    KAPITOL_TERMS_AND_CONDITIONS_URL,
} from "../../constants";

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    street: "",
    streetNumber: "",
    city: "",
    postcode: "",
    countryId: "",
    termsAndConditionsCm: false,
    gdprCm: false,
    termsAndConditions: false,
    gdpr: false,
    shippingAddress: true,
    shippingAddressFirstName: "",
    shippingAddressLastName: "",
    shippingAddressContactEmail: "",
    shippingAddressPhone: "",
    shippingAddressStreet: "",
    shippingAddressStreetNumber: "",
    shippingAddressCity: "",
    shippingAddressPostCode: "",
    shippingAddressCountryId: "",
    birthdate: "",
    type: "person",
    companyName: "",
    registrationNumber: "",
    vatNumber: "",
    isVatPayer: false,
};

const orderSchema = yup
    .object()
    .shape({
        firstName: yup.string().required("Vyplňte jméno"),
        lastName: yup.string().required("Vyplňte příjmení"),
        mobile: yup
            .string()
            .matches(/^(\+?420)?[6,7]{1}[0-9]{8}$/, {
                message:
                    "Neplatné mobilní číslo (zadejte ve tvaru 123456789 nebo +420123456789)",
                excludeEmptyString: true,
            })
            .max(13, "Mobilní číslo je moc dlouhé")
            .min(9, "Mobilní číslo je moc krátké")
            .required("Vyplňte mobilní telefonní číslo"),
        email: yup
            .string()
            .email("Vyplňte platnou e-mailovou adresu")
            .required("Vyplňte e-mailovou adresu"),
        birthdate: yup
            .date()
            .max(
                EIGHTEEN_YEARS_AGO,
                "Musíte být starší 18-ti let pro objednání."
            )
            .required("Vyplňte datum narození"),

        street: yup.string().required("Vyplňte ulici"),
        streetNumber: yup.string().required("Vyplňte číslo popisné"),
        city: yup.string().required("Vyplňte město"),
        postcode: yup
            .string()
            .matches(/^[1-9]{1}[0-9]{4}$/, {
                message: "Vyplňte PSČ bez mezer",
                excludeEmptyString: true,
            })
            .required("Vyplňte PSČ"),
        shippingAddress: yup.bool(),
        shippingAddressFirstName: yup.string().when(["shippingAddress"], {
            is: false,
            then: yup.string().required("Vyplňte jméno"),
        }),
        shippingAddressLastName: yup.string().when(["shippingAddress"], {
            is: false,
            then: yup.string().required("Vyplňte příjmení"),
        }),
        shippingAddressContactEmail: yup.string().when(["shippingAddress"], {
            is: false,
            then: yup
                .string()
                .email("Vyplňte platnou e-mailovou adresu")
                .required("Vyplňte e-mailovou adresu"),
        }),

        shippingAddressPhone: yup.string().when(["shippingAddress"], {
            is: false,
            then: yup
                .string()
                .matches(/^(\+?420)?[6,7]{1}[0-9]{8}$/, {
                    message:
                        "Neplatné mobilní číslo (zadejte ve tvaru 123456789 nebo +420123456789)",
                    excludeEmptyString: true,
                })
                .max(13, "Mobilní číslo je moc dlouhé")
                .min(9, "Mobilní číslo je moc krátké")
                .required("Vyplňte mobilní telefonní číslo"),
        }),

        shippingAddressStreet: yup.string().when(["shippingAddress"], {
            is: false,
            then: yup.string().required("Vyplňte ulici"),
        }),
        shippingAddressStreetNumber: yup.string().when(["shippingAddress"], {
            is: false,
            then: yup.string().required("Vyplňte číslo popisné"),
        }),
        shippingAddressCity: yup.string().when(["shippingAddress"], {
            is: false,
            then: yup.string().required("Vyplňte město"),
        }),
        shippingAddressPostCode: yup.string().when(["shippingAddress"], {
            is: false,
            then: yup
                .string()
                .matches(/^[1-9]{1}[0-9]{4}$/, {
                    message: "Vyplňte PSČ bez mezer",
                    excludeEmptyString: true,
                })
                .required("Vyplňte PSČ"),
        }),
        termsAndConditionsCm: yup
            .bool()
            .oneOf(
                [true],
                "Musíte souhlasit s Všeobecnými obchodními podmínkami společnosti Česká Mincovna, a.s."
            ),
        gdprCm: yup
            .bool()
            .oneOf(
                [true],
                "Musíte souhlasit s Podmínkami zpracování osobních údajů společnosti Česká Mincovna, a.s."
            ),
        termsAndConditions: yup
            .bool()
            .oneOf(
                [true],
                "Musíte souhlasit s Obchodními podmínkami společnosti KAPITOL, a.s."
            ),
        gdpr: yup
            .bool()
            .oneOf(
                [true],
                "Musíte souhlasit se Zásadami o ochraně osobních údajů společnosti KAPITOL, a.s."
            ),

        companyName: yup.string().when(["type"], {
            is: "company",
            then: yup.string().required("Vyplňte jméno firmy"),
        }),

        vatNumber: yup.string().when(["type"], {
            is: "company",
            then: yup.string().required("Vyplňte DIČ"),
        }),
    })
    .test(
        // this test is added additional to any other (build-in) tests
        "myCustomTest",
        null, // we'll return error message ourself if needed
        obj => {
            if (obj.type === "person") {
                return true;
            }

            if (validateIco(obj.registrationNumber) === true) {
                return true;
            }
            return new yup.ValidationError(
                "Vyplňte IČO",
                null,
                "registrationNumber"
            );
        }
    );

const validateIco = ico => {
    try {
        let a = 0;
        if (ico.length == 0) return false;
        if (ico.length != 8) throw 1;

        let b = ico.split("");
        let c = 0;
        for (let i = 0; i < 7; i++) a += parseInt(b[i]) * (8 - i);
        a = a % 11;
        c = 11 - a;
        if (a == 1) c = 0;
        if (a == 0) c = 1;
        if (a == 10) c = 1;
        if (parseInt(b[7]) != c) throw 1;
    } catch (e) {
        return false;
    }
    return true;
};

const CheckoutForm = props => {
    const formClasses = cx({
        [orderStyle.form]: true,
        [orderStyle.horizontal]: true,
    });
    const { contactFormCondition } = props;
    const [submitError] = useState(false);
    const { setCheckoutForm, checkoutForm } = useContext(checkoutContext);
    const [aresLoading, setAresLoading] = useState(false);

    const getAresData = async registrationNumber => {
        setAresLoading(true);
        const result = await sendIcoMutation(registrationNumber);
        setAresLoading(false);
        return result;
    };

    return (
        <div className={orderStyle.form}>
            <Formik
                initialValues={checkoutForm ? checkoutForm : initialState}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);

                    await setCheckoutForm(values);
                    navigate("/e-shop/confirmation");
                }}
                validationSchema={orderSchema}
                render={props => (
                    <>
                        <form
                            onSubmit={props.handleSubmit}
                            className={formClasses}
                        >
                            <div className={orderStyle.formLabel} id="invoice">
                                osobní údaje
                            </div>
                            <div className={orderStyle.inline}>
                                <Field
                                    name="firstName"
                                    component={CustomInputComponent}
                                    label="Jméno *"
                                    horizontal="horizontal"
                                />
                                <Field
                                    name="lastName"
                                    component={CustomInputComponent}
                                    label="Příjmení *"
                                    horizontal="horizontal"
                                />
                            </div>
                            <div className={orderStyle.inline}>
                                <Field
                                    name="email"
                                    component={CustomInputComponent}
                                    label="E-mail *"
                                    horizontal="horizontal"
                                    type="email"
                                />
                                <Field
                                    name="mobile"
                                    component={CustomInputComponent}
                                    label="Mobilní telefonní číslo *"
                                    horizontal="horizontal"
                                />
                            </div>
                            <div className={orderStyle.inline}>
                                <div>
                                    <Field
                                        name="birthdate"
                                        component={BirthDateInput}
                                        label="datum narození"
                                    />
                                </div>
                            </div>
                            <div className={orderStyle.formLabel}>
                                Fakturační adresa
                            </div>
                            <div className={orderStyle.inlineFull}>
                                <RadioButtonGroup
                                    id="radioGroup"
                                    value={props.values.radioGroup}
                                    error={props.errors.radioGroup}
                                    touched={props.touched.radioGroup}
                                    label="FAKTURAČNÍ ÚDAJE"
                                >
                                    <Field
                                        component={RadioButton}
                                        name="type"
                                        id="person"
                                        label="Soukromá osoba"
                                    />
                                    <Field
                                        component={RadioButton}
                                        name="type"
                                        id="company"
                                        label="Firma (pouze pro plátce DPH)"
                                    />
                                </RadioButtonGroup>
                            </div>
                            {props.values && props.values.type == "company" && (
                                <>
                                    <div className={orderStyle.inlineBig}>
                                        <Field
                                            name="registrationNumber"
                                            component={CustomCompanyComponent}
                                            label="IČO *"
                                            getAresData={getAresData}
                                            validateIco={validateIco}
                                        />
                                        <div>
                                            {aresLoading && (
                                                <span>
                                                    Načítám data o firmě
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className={orderStyle.inlineBig}>
                                        <Field
                                            name="vatNumber"
                                            component={CustomInputComponent}
                                            label="DIČ *"
                                            getAresData={getAresData}
                                        />
                                        <div></div>
                                    </div>

                                    <div className={orderStyle.inlineBig}>
                                        <Field
                                            name="companyName"
                                            component={CustomInputComponent}
                                            label="Jméno firmy *"
                                            getAresData={getAresData}
                                        />
                                        <div></div>
                                    </div>
                                </>
                            )}

                            <div className={orderStyle.inlineBig}>
                                <Field
                                    name="street"
                                    component={CustomInputComponent}
                                    label="Ulice *"
                                />
                                <Field
                                    name="streetNumber"
                                    component={CustomInputComponent}
                                    label="Číslo popisné *"
                                />
                            </div>
                            <div className={orderStyle.inlineBig}>
                                <Field
                                    name="city"
                                    component={CustomInputComponent}
                                    label="Město *"
                                />
                                <Field
                                    name="postcode"
                                    component={CustomInputComponent}
                                    label="psč *"
                                />
                            </div>
                            <div className={orderStyle.inline}>
                                <div>
                                    <label className={orderStyle.label}>
                                        <span>země</span>
                                    </label>
                                    <select
                                        name="countryId"
                                        className={orderStyle.select}
                                        value={props.values.countryId}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    >
                                        <option value="Česká republika">
                                            Česká republika
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className={orderStyle.formLabel}>
                                Dodací adresa
                            </div>
                            <div
                                className={orderStyle.inlineFull}
                                id="shipping"
                            >
                                <Field
                                    component={Checkbox}
                                    name="shippingAddress"
                                    id="shippingAddress"
                                >
                                    <label htmlFor="shippingAddress">
                                        {contactFormCondition}
                                        Dodací adresa je stejná jako fakturační
                                    </label>
                                </Field>
                            </div>
                            {props.values &&
                                props.values.shippingAddress == false && (
                                    <>
                                        <div className={orderStyle.inline}>
                                            <Field
                                                name="shippingAddressFirstName"
                                                component={CustomInputComponent}
                                                label="Jméno *"
                                                horizontal="horizontal"
                                            />
                                            <Field
                                                name="shippingAddressLastName"
                                                component={CustomInputComponent}
                                                label="Příjmení *"
                                                horizontal="horizontal"
                                            />
                                        </div>
                                        <div className={orderStyle.inline}>
                                            <Field
                                                name="shippingAddressContactEmail"
                                                component={CustomInputComponent}
                                                label="E-mail *"
                                                horizontal="horizontal"
                                            />
                                            <Field
                                                name="shippingAddressPhone"
                                                component={CustomInputComponent}
                                                label="Mobilní telefonní číslo *"
                                                horizontal="horizontal"
                                            />
                                        </div>
                                        <div className={orderStyle.inlineBig}>
                                            <Field
                                                name="shippingAddressStreet"
                                                component={CustomInputComponent}
                                                label="Ulice *"
                                            />
                                            <Field
                                                name="shippingAddressStreetNumber"
                                                component={CustomInputComponent}
                                                label="Číslo popisné *"
                                            />
                                        </div>
                                        <div className={orderStyle.inlineBig}>
                                            <Field
                                                name="shippingAddressCity"
                                                component={CustomInputComponent}
                                                label="Město *"
                                            />
                                            <Field
                                                name="shippingAddressPostCode"
                                                component={CustomInputComponent}
                                                label="psč *"
                                            />
                                        </div>
                                        <div className={orderStyle.inline}>
                                            <div>
                                                <label
                                                    className={orderStyle.label}
                                                >
                                                    <span>země</span>
                                                </label>
                                                <select
                                                    name="shippingAddressCountryId"
                                                    className={
                                                        orderStyle.select
                                                    }
                                                    value={
                                                        props.values
                                                            .shippingAddressCountryId
                                                    }
                                                    onChange={
                                                        props.handleChange
                                                    }
                                                    onBlur={props.handleBlur}
                                                >
                                                    <option value="Česká republika">
                                                        Česká republika
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                )}
                            <div className={orderStyle.formLabel}>Souhlasy</div>
                            <p className={orderStyle.textCm}>
                                Prodávajícím zboží (dále jen “Prodávající”) je
                                společnost Česká mincovna, a.s., se sídlem U
                                Přehrady 3204/61, Mšeno nad Nisou, 466 23
                                Jablonec nad Nisou, IČ: 287 37 016, DIČ
                                CZ28737016, zapsaná v obchodním rejstříku u
                                Krajského soudu v Ústí nad Labem, sp. zn. B
                                2168, která vyřizuje objednávky, přijímá platby
                                za zboží, zboží odesílá zákazníkům a vyřizuje
                                odkup, reklamace nebo jiná uplatnění práv
                                zákazníků. Více informací ohledně platby dopravy
                                a obchodních podmínkách najdete na webu
                                Prodávajícího:{" "}
                                <a
                                    target={"_blank"}
                                    rel={"noreferrer noopener"}
                                    className={orderStyle.link}
                                    href={"https://ceskamincovna.cz/"}
                                >
                                    https://ceskamincovna.cz/
                                </a>
                                . Společnost KAPITOL, a.s. provozující tento
                                e-shop je pouze zprostředkovatelem prodeje.
                            </p>
                            <div className={orderStyle.inlineFull}>
                                <Field
                                    component={Checkbox}
                                    name="termsAndConditionsCm"
                                    id="termsAndConditionsCm"
                                >
                                    <label htmlFor="termsAndConditionsCm">
                                        Seznámil/a jsem se a souhlasím s{" "}
                                        <a
                                            target={"_blank"}
                                            rel={"noreferrer noopener"}
                                            href={CM_TERMS_AND_CONDITIONS_URL}
                                        >
                                            Všeobecnými obchodními podmínkami
                                        </a>{" "}
                                        společnosti Česká mincovna, a.s.
                                    </label>
                                </Field>
                            </div>
                            <div className={orderStyle.inlineFull}>
                                <Field
                                    component={Checkbox}
                                    name="gdprCm"
                                    id="gdprCm"
                                >
                                    <label htmlFor="gdprCm">
                                        Seznámil/a jsem se a souhlasím s{" "}
                                        <a
                                            target={"_blank"}
                                            rel={"noreferrer noopener"}
                                            href={CM_GDPR_URL}
                                        >
                                            Podmínkami zpracování osobních údajů
                                            pro klienty
                                        </a>{" "}
                                        společnosti Česká mincovna, a.s.
                                    </label>
                                </Field>
                            </div>
                            <div className={orderStyle.inlineFull}>
                                <Field
                                    component={Checkbox}
                                    name="termsAndConditions"
                                    id="termsAndConditions"
                                >
                                    <label htmlFor="termsAndConditions">
                                        Seznámil/a jsem se a souhlasím s{" "}
                                        <a
                                            target={"_blank"}
                                            rel={"noreferrer noopener"}
                                            href={
                                                KAPITOL_TERMS_AND_CONDITIONS_URL
                                            }
                                        >
                                            Obchodními podmínkami
                                        </a>{" "}
                                        společnosti KAPITOL, a.s.
                                    </label>
                                </Field>
                            </div>
                            <div className={orderStyle.inlineFull}>
                                <Field
                                    component={Checkbox}
                                    name="gdpr"
                                    id="gdpr"
                                >
                                    <label htmlFor="gdpr">
                                        Seznámil/a jsem se se{" "}
                                        <a
                                            target={"_blank"}
                                            rel={"noreferrer noopener"}
                                            href={KAPITOL_GDPR_URL}
                                        >
                                            Zásadami zpracování osobních
                                            osobních údajů
                                        </a>{" "}
                                        společnosti KAPITOL, a.s.
                                    </label>
                                </Field>
                            </div>
                            {submitError && (
                                <div className={orderStyle.error}>
                                    Objednávku se nepodařilo odeslat prosím
                                    zkuste odeslat znovu nebo kontaktujte
                                    obchodní zastoupení.
                                </div>
                            )}
                            <CheckoutButtons>
                                <Button
                                    type="link"
                                    disabled={props.isSubmitting}
                                    mt={20}
                                    uri="/e-shop/cart/"
                                    color="outline-gold-dark"
                                >
                                    Zpět
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={props.isSubmitting}
                                    mt={20}
                                    data-test="next-button"
                                >
                                    {props.isSubmitting
                                        ? "Ukládám…"
                                        : "Pokračovat"}
                                </Button>
                            </CheckoutButtons>
                        </form>
                    </>
                )}
            />
        </div>
    );
};

export default CheckoutForm;
