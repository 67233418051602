import React from "react";
import SiteLayout from "../../components/SiteLayout";
import Block from "../../components/Block/";

import CheckoutDelivery from "../../components/CheckoutDelivery/CheckoutDelivery";
import CheckoutSteps from "../../components/CheckoutSteps/CheckoutSteps";

const Checkout = props => (
    <SiteLayout location={props.location} checkout={true}>
        <Block background={true} radialGradient={true} cart={true}>
            <CheckoutSteps step={1} />
            <CheckoutDelivery />
        </Block>
    </SiteLayout>
);

export default Checkout;
