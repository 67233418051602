import React, { useContext, useEffect } from "react";
import Block from "../../components/Block/";
import classNames from "classnames";
import style from "./checkoutSteps.module.sass";

import { measuringCheckoutSteps } from "../../utils/gtm";
import { checkoutContext } from "../../context/checkoutContextProvider";

const CheckoutSteps = ({ step }) => {
    const { cart } = useContext(checkoutContext);
    useEffect(() => {
        measuringCheckoutSteps(cart, step + 1);
    }, [step]);

    return (
        <section className={style.steps}>
            <Block cart={true}>
                <div className={style.stepsContainer}>
                    <ul>
                        <li
                            className={classNames({
                                [style.active]: step == 1 ? style.active : "",
                                [style.first]: true,
                            })}
                        >
                            dodací údaje
                        </li>
                        <li
                            className={classNames({
                                [style.active]:
                                    step == 2 || step == 3 ? style.active : "",
                                [style.second]: true,
                            })}
                        >
                            souhrn
                        </li>
                        <li
                            className={classNames({
                                [style.active]: step == 3 ? style.active : "",
                                [style.third]: true,
                            })}
                        >
                            potvrzení nákupu
                        </li>
                    </ul>
                </div>
            </Block>
        </section>
    );
};

export default CheckoutSteps;
