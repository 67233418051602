import React, { useContext, useEffect } from "react";
import accounting from "accounting";
import { navigate } from "gatsby";
import { checkoutContext } from "../../context/checkoutContextProvider";
import { cartSum } from "../../components/Cart/cartSum";
import style from "./checkoutCart.module.sass";

const CheckoutCart = () => {
    const { cart } = useContext(checkoutContext);
    //TODO: prompt pro leave page

    const cartTotal = cartSum(cart);
    const emptyCart = Object.entries(cart).length === 0;
    useEffect(() => {
        if (emptyCart) {
            navigate("/e-shop/cart/");
        }
    }, []);
    return (
        <section className={style.wrapper}>
            <div className={style.inner}>
                <div className={style.title}>
                    NÁKUPNÍ KOŠÍK
                    <svg
                        width="26"
                        height="20"
                        viewBox="0 0 26 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.5 10L8.5 16M13.5 10V16M18.5 10V16"
                            stroke="#AD9752"
                            strokeLinecap="round"
                        />
                        <path
                            d="M5 7L7 4L9 1"
                            stroke="#AD9752"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M21 7L19 4L17 1"
                            stroke="#AD9752"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M1 7H25"
                            stroke="#AD9752"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M3 7V16C3 17.6569 4.34315 19 6 19H20C21.6569 19 23 17.6569 23 16V7"
                            stroke="#AD9752"
                        />
                    </svg>
                </div>
                <ul className={style.items}>
                    {cart &&
                        Object.entries(cart).map(([key, value]) => (
                            <li key={value.productId} className={style.item}>
                                <div>
                                    <div className={style.amount}>
                                        {value.quantity} ks
                                    </div>
                                    <div className={style.name}>
                                        {value.productName}
                                    </div>
                                </div>
                                <div className={style.price}>
                                    {accounting.formatNumber(
                                        value.itemPrice * value.quantity,
                                        0,
                                        " ",
                                        ","
                                    )}
                                    &nbsp;Kč
                                </div>
                            </li>
                        ))}
                    <li>
                        <div className={style.delivery}>
                            Doručení (Česká pošta)
                        </div>
                        <div className={style.price}>
                            {cartTotal.delivery === "zdarma"
                                ? cartTotal.delivery
                                : `${cartTotal.delivery} Kč`}
                        </div>
                    </li>
                </ul>
                <div className={style.total}>
                    <div>Celkem vč. DPH</div>
                    <div className={style.price}>
                        {accounting.formatNumber(
                            cartTotal.totalPrice,
                            0,
                            " ",
                            ","
                        )}
                        <span className={style.currency}>&nbsp;Kč</span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CheckoutCart;
