import React from "react";
import CheckoutForm from "../../components/CheckoutForm/CheckoutForm";
import CheckoutCart from "../../components/CheckoutCart/CheckoutCart";
import style from "./checkout.module.sass";

const CheckoutDelivery = props => (
    <div className={style.row}>
        <CheckoutForm />
        <CheckoutCart />
    </div>
);

export default CheckoutDelivery;
