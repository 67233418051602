const query = `mutation GET_ARES_DATA($input: GetAresDataInput!) {
  getAresData(input: $input) {
    status
        aresData{
        in
        tin
        active
        company
        vat_payer
    }
  }
}
`;

export default async registrationNumber => {
    const graphqlUrl =
        process.env.WP_GRAPHQL_URI ||
        process.env.GATSBY_WP_GRAPHQL_URI ||
        "/graphql";

    const result = await fetch(graphqlUrl, {
        // eslint-disable-line no-undef
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({
            query,
            variables: {
                input: {
                    clientMutationId: "order",
                    registrationNumber: registrationNumber,
                },
            },
        }),
    });
    const data = await result.json();

    return data.data.getAresData.aresData;
};
