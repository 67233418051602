import React from "react";
import PropTypes from "prop-types";

import style from "./checkoutButtons.module.sass";

const CheckoutButtons = ({ children }) => (
    <div className={style.buttons}>{children}</div>
);

CheckoutButtons.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CheckoutButtons;
